import { AuthData, useApi } from '@greenisland/stores';
import { EntitySchema, ProductSchema } from '@greenisland-store/authorization';
import ky, { Options } from 'ky';

const BASE_URL = 'https://boauthdev.greenisland.be/star/starcasino';
interface RequestConfig {
  method: 'get' | 'put' | 'patch' | 'post' | 'delete';
  url: string;
  params?: any;
  data?: any;
  responseType?: string;
  signal?: AbortSignal;
  headers?: HeadersInit;
}

const intercept = async (request: Request, options: Options, response: Response) => {
  if (response.status === 401) {
    localStorage.removeItem('currentAccount');
    localStorage.removeItem('startupData');
    sessionStorage.removeItem('selectedEntity');
    sessionStorage.removeItem('loggedInAgent');
    window.location.href = '/login';
  }
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(await response.json());
  }
};

const api = ky.extend({ prefixUrl: BASE_URL, hooks: { afterResponse: [intercept] }, timeout: 120000 });

export const useCustomInstance = <T>(): ((config: RequestConfig) => Promise<T>) => {
  const baseUrl = useApi();
  const loggedInAgent: string | null = sessionStorage.getItem('loggedInAgent');
  const data: AuthData = loggedInAgent ? JSON.parse(loggedInAgent) : null;
  const tenantConfig = sessionStorage.getItem('selectedEntity');
  const { selectedEntity: entityId, selectedProduct: productId } = tenantConfig
    ? JSON.parse(tenantConfig)
    : { selectedEntity: undefined, selectedProduct: undefined };

  let productType: string | undefined = undefined;

  const entity: EntitySchema | undefined =
    data?.startupAgent && data?.startupAgent?.entities
      ? data.startupAgent.entities.find((indEntity: EntitySchema) => {
          if (indEntity.entityName === entityId) return indEntity;
        })
      : undefined;

  if (entity) {
    entity.products.find((product: ProductSchema) => {
      if (product.productName === productId) {
        productType = product.productType;
      }
    });
  }

  return (config: RequestConfig) => {
    const endp =
      entityId?.length > 0 && productId?.length > 0 && config.url !== '/authorization/startup'
        ? productType
          ? `/${productType}/${entityId}/${productId}`
          : `/${entityId}/${productId}`
        : '';

    const prefixUrl = `${baseUrl}${endp}`;

    const controller = new AbortController();
    const { signal } = controller;
    const promise = api(`${config.url.substring(1)}`, {
      method: config.method,
      json: config.data,
      searchParams: config.params,
      headers: {
        ...config.headers,
        authorization: `Bearer ${data.token}`,
      },
      prefixUrl,
      signal,
    });
    return promise.json<T>();
  };
};

export default useCustomInstance;
