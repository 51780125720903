import '../../../../../../common/helpers/prettyPrintJson/prettyPrintCss.css';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import {
  EWithdrawalStatusSchema,
  getGetWithdrawalDetailsV2QueryKey,
  useCancelFailedWithdrawalV2,
  useCompleteWithdrawalManuallyV2,
  useDeclineWithdrawalV2,
  useFailWithdrawalV2,
  useGetWithdrawalDetailsV2,
  useProcessWithdrawalsV2,
  useRetryWithdrawalV2,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader, Stack, styled, Tab, Tabs, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import { StyledDataGrid, TabPanel } from '@greenisland-common/components/atoms';

import { prettyPrintJson } from '@greenisland-common/helpers';

import {
  formatCurrency,
  transformDefaultV2,
  transformUnixDateV2,
} from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../../LookupPermissions';
import DeclineWithdrawalDialog from './DeclineWithdrawalDialog';

const DetailsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const Account = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));

const SubDetails = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const WithdrawalDetails = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { userId = '', withdrawalId = '' } = useParams();
  const [stream, setStream] = useState<number>(0);
  const [declineDialogOpen, setDeclineDialogOpen] = useState<boolean>(false);
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canCompleteWithdrawal = usePermission(OnlineCasinoPermissions.completeWithdrawalManuallyV2);
  const canFailWithdrawal = usePermission(OnlineCasinoPermissions.failWithdrawalV2);
  const canDeclineWithdrawal = usePermission(OnlineCasinoPermissions.declineWithdrawalV2);
  const canCancelWithdrawal = usePermission(OnlineCasinoPermissions.cancelFailedWithdrawalV2);
  const canProcessWithdrawal = usePermission(OnlineCasinoPermissions.processWithdrawalsV2);
  const canRetryWithdrawal = usePermission(OnlineCasinoPermissions.retryWithdrawalV2);
  const canReadWithdrawalDetails = usePermission(OnlineCasinoPermissions.getWithdrawalDetailsV2);
  const { data: details, isLoading: isLoadingDetails } = useGetWithdrawalDetailsV2(parseInt(withdrawalId), undefined, {
    query: {
      enabled: canReadWithdrawalDetails,
      onError: () => {
        enqueueSnackbar(t('witdrawals.errors.loadingDetails'), { variant: 'error' });
      },
    },
  });
  const { mutate: completeManually, isLoading: isCompletingManually } = useCompleteWithdrawalManuallyV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetWithdrawalDetailsV2QueryKey(parseInt(withdrawalId)));
        enqueueSnackbar(t('withdrawals.completedManually'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('withdrawals.errors.completingManually'), { variant: 'error' });
      },
    },
  });
  const { mutate: failManually, isLoading: isFailingManually } = useFailWithdrawalV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetWithdrawalDetailsV2QueryKey(parseInt(withdrawalId)));
        enqueueSnackbar(t('withdrawals.failedManually'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('withdrawals.errors.failedManually'), { variant: 'error' });
      },
    },
  });
  const { mutate: decline, isLoading: isDeclining } = useDeclineWithdrawalV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetWithdrawalDetailsV2QueryKey(parseInt(withdrawalId)));
        enqueueSnackbar(t('withdrawals.declined'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('withdrawals.errors.decline'), { variant: 'error' });
      },
    },
  });
  const { mutate: cancel, isLoading: isCancelling } = useCancelFailedWithdrawalV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetWithdrawalDetailsV2QueryKey(parseInt(withdrawalId)));
        enqueueSnackbar(t('withdrawals.cancelled'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('withdrawals.errors.cancel'), { variant: 'error' });
      },
    },
  });
  const { mutate: process, isLoading: isProcessing } = useProcessWithdrawalsV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetWithdrawalDetailsV2QueryKey(parseInt(withdrawalId)));
        enqueueSnackbar(t('withdrawals.processed'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('withdrawals.errors.processing'), { variant: 'error' });
      },
    },
  });
  const { mutate: retry, isLoading: isRetrying } = useRetryWithdrawalV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetWithdrawalDetailsV2QueryKey(parseInt(withdrawalId)));
        enqueueSnackbar(t('withdrawals.retried'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('withdrawals.errors.retrying'), { variant: 'error' });
      },
    },
  });

  const isLoading =
    isCompletingManually || isDeclining || isCancelling || isProcessing || isRetrying || isFailingManually;

  const getPrettyPrintValue = (value: string) => {
    try {
      return JSON.parse(value);
    } catch (e: any) {
      return value;
    }
  };

  const mutatedMessageData = useMemo(() => {
    if (!details?.messages || details.messages.length === 0) return { columns: [], rows: [] };
    const columns: GridColDef[] = Object.keys(details.messages[0]).map(key => {
      switch (key) {
        case 'payload':
          return {
            field: key,
            headerName: t(key),
            flex: 1,
            renderCell: params => (
              <pre
                dangerouslySetInnerHTML={{
                  __html: prettyPrintJson(getPrettyPrintValue(params.value), { linkUrls: true }),
                }}
              />
            ),
            sortable: false,
          };
        case 'timestamp':
          return transformUnixDateV2(key, t, true);
        default:
          return transformDefaultV2(key, t);
      }
    });

    const rows = details.messages.map((item, index) => ({ ...item, id: index }));
    return { columns, rows };
  }, [details, t]);

  const mutatedEventStreamData = useMemo(() => {
    if (!details?.events || details.events.length === 0) return { columns: [], rows: [] };
    const columns: GridColDef[] = Object.keys(details.events[0]).map(key => {
      switch (key) {
        case 'payload':
          return {
            field: key,
            headerName: t(key),
            flex: 1,
            renderCell: params => (
              <pre
                dangerouslySetInnerHTML={{
                  __html: prettyPrintJson(getPrettyPrintValue(params.value), { linkUrls: true }),
                }}
              />
            ),
            sortable: false,
          };
        case 'timestamp':
          return {
            ...transformUnixDateV2(key, t, true),
            flex: 0.5,
          };
        default:
          return {
            ...transformDefaultV2(key, t),
            flex: 0.5,
          };
      }
    });

    const rows = details.events.map((item, index) => ({ ...item, id: index }));
    return { columns, rows };
  }, [details, t]);

  const handleCompleteManually = () => {
    confirm({
      title: t('withdrawals.confirmations.complete.title'),
      description: t('withdrawals.confirmations.complete.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('withdrawals.confirmations.proceed'),
    }).then(() => completeManually({ withdrawalId: parseInt(withdrawalId) }));
  };

  const handleFailManually = () => {
    confirm({
      title: t('withdrawals.confirmations.fail.title'),
      description: t('withdrawals.confirmations.fail.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('withdrawals.confirmations.proceed'),
    }).then(() => failManually({ withdrawalId: parseInt(withdrawalId) }));
  };

  const handleDecline = (reason: string) => {
    decline({ withdrawalId: parseInt(withdrawalId), data: reason });
  };

  const handleCancel = () => {
    confirm({
      title: t('withdrawals.confirmations.cancel.title'),
      description: t('withdrawals.confirmations.cancel.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('withdrawals.confirmations.proceed'),
    }).then(() => cancel({ withdrawalId: parseInt(withdrawalId) }));
  };

  const handleProcess = () => {
    confirm({
      title: t('withdrawals.confirmations.process.title'),
      description: t('withdrawals.confirmations.process.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('withdrawals.confirmations.proceed'),
    }).then(() => process({ data: [parseInt(withdrawalId)] }));
  };

  const handleRetry = () => {
    confirm({
      title: t('withdrawals.confirmations.retry.title'),
      description: t('withdrawals.confirmations.retry.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('withdrawals.confirmations.proceed'),
    }).then(() => retry({ withdrawalId: parseInt(withdrawalId) }));
  };

  const completeManuallyStatuses: EWithdrawalStatusSchema[] = [
    EWithdrawalStatusSchema.ProcessingFailed,
    EWithdrawalStatusSchema.Approved,
  ];
  const showCompleteManuallyButton =
    canCompleteWithdrawal && details?.status && completeManuallyStatuses.includes(details.status);

  const failManuallyStatuses: EWithdrawalStatusSchema[] = [
    EWithdrawalStatusSchema.Approved,
    EWithdrawalStatusSchema.Pended,
  ];
  const showFailManuallyButton = canFailWithdrawal && details?.status && failManuallyStatuses.includes(details.status);

  const declineStatuses: EWithdrawalStatusSchema[] = [EWithdrawalStatusSchema.New];
  const showDeclineButton = canDeclineWithdrawal && details?.status && declineStatuses.includes(details.status);

  const cancelStatuses: EWithdrawalStatusSchema[] = [EWithdrawalStatusSchema.ProcessingFailed];
  const showCancelButton = canCancelWithdrawal && details?.status && cancelStatuses.includes(details.status);

  const processStatuses: EWithdrawalStatusSchema[] = [EWithdrawalStatusSchema.New];
  const showProcessButton = canProcessWithdrawal && details?.status && processStatuses.includes(details.status);

  const retryStatuses: EWithdrawalStatusSchema[] = [EWithdrawalStatusSchema.ProcessingFailed];
  const showRetryButton = canRetryWithdrawal && details?.status && retryStatuses.includes(details.status);

  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader title={t('details')} />
        <CardContent>
          {isLoadingDetails ? (
            <Typography>{t('loading')}</Typography>
          ) : details ? (
            <DetailsContainer>
              <Box>
                <Account>
                  {canLookupUser ? (
                    <Typography component="a" href={`../../../users/${userId}/details`}>
                      {t('User')} - {userId}
                    </Typography>
                  ) : (
                    <Typography>{userId}</Typography>
                  )}
                </Account>
                <SubDetails>
                  {t('withdrawalId')} - {withdrawalId}
                </SubDetails>
              </Box>
              <Box>
                <Typography>{formatCurrency(details.amount)}</Typography>
                <Typography>{details?.destination}</Typography>
              </Box>
              <Box>
                <Typography>{t('status')}:</Typography>
                <Typography variant="body1" fontWeight="bold">
                  {details.status}
                </Typography>
              </Box>
              <Box>
                <Typography align="center">{t('withdrawals.actions.title')}:</Typography>
                <Stack direction="column" spacing={1} p={1}>
                  {showCompleteManuallyButton && (
                    <Button onClick={handleCompleteManually} variant="contained" disabled={isLoading} size="small">
                      {t('withdrawals.actions.complete')}
                    </Button>
                  )}
                  {showFailManuallyButton && (
                    <Button
                      onClick={handleFailManually}
                      variant="contained"
                      disabled={isLoading}
                      color="error"
                      size="small"
                    >
                      {t('withdrawals.actions.fail')}
                    </Button>
                  )}
                  {showDeclineButton && (
                    <Button
                      onClick={() => setDeclineDialogOpen(true)}
                      variant="contained"
                      disabled={isLoading}
                      color="error"
                      size="small"
                    >
                      {t('withdrawals.actions.decline')}
                    </Button>
                  )}
                  {declineDialogOpen && (
                    <DeclineWithdrawalDialog close={() => setDeclineDialogOpen(false)} submit={handleDecline} />
                  )}
                  {showCancelButton && (
                    <Button onClick={handleCancel} variant="contained" disabled={isLoading} color="error" size="small">
                      {t('withdrawals.actions.cancel')}
                    </Button>
                  )}
                  {showProcessButton && (
                    <Button onClick={handleProcess} variant="contained" disabled={isLoading} size="small">
                      {t('withdrawals.actions.approve')}
                    </Button>
                  )}
                  {showRetryButton && (
                    <Button onClick={handleRetry} variant="contained" disabled={isLoading} size="small">
                      {t('withdrawals.actions.retry')}
                    </Button>
                  )}
                </Stack>
              </Box>
            </DetailsContainer>
          ) : (
            <Typography>{t('noData')}</Typography>
          )}
        </CardContent>
      </Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={stream} onChange={(event, newValue) => setStream(newValue)}>
          <Tab label={t('messages')} value={0} />
          <Tab label={t('events')} value={1} />
        </Tabs>
      </Box>
      <TabPanel value={stream} index={0}>
        <StyledDataGrid
          columns={mutatedMessageData.columns}
          rows={mutatedMessageData.rows}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          autoHeight
          getRowHeight={() => 'auto'}
        />
      </TabPanel>
      <TabPanel value={stream} index={1}>
        <StyledDataGrid
          columns={mutatedEventStreamData.columns}
          rows={mutatedEventStreamData.rows}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          autoHeight
          getRowHeight={() => 'auto'}
        />
      </TabPanel>
    </Stack>
  );
};

export default WithdrawalDetails;
